<template>
    <header class="page-title">
        <h1><i class="bi bi-calculator-fill"></i> 収支一覧</h1>
    </header>

    <ul class="nav nav-tabs px-3">
        <li class="nav-item">
            <router-link :to="{name: 'Profit'}" class="nav-link active">月別</router-link>
        </li>
        <li class="nav-item">
            <router-link :to="{name: 'ProfitProject'}" class="nav-link">案件別</router-link>
        </li>
    </ul>

    <section class="section">

        <form @submit.prevent="fetch()" class="mb-3">
            <div class="row mb-3">
                <div class="d-flex gap-3 col-7">
                    <button type="button" class="btn btn-secondary" @click="fetchPrev()"><i class="bi bi-caret-left"></i></button>
                    <form-input-month v-model="month" required></form-input-month>
                    <button type="submit" class="btn btn-primary">表示</button>
                    <button type="button" class="btn btn-secondary" @click="fetchNext()"><i class="bi bi-caret-right"></i></button>
                </div>
                <div class="col-6 ms-auto text-end">
                    <span v-if="$store.getters['auth/canExportCsv']()" class="dropdown">
                        <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="bi bi-download"></i> CSV
                        </button>
                        <ul class="dropdown-menu">
                            <li><button class="dropdown-item" type="button" @click="exportCsv()">CSVエクスポート</button></li>
                            <li><router-link :to="{name: 'Export'}" class="dropdown-item">エクスポート履歴</router-link></li>
                        </ul>
                    </span>
                    <button v-else type="button" class="btn btn-outline-primary" disabled><i class="bi bi-download"></i> CSV</button>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <form-select v-model="department" :options="departments" option_value="department_id" option_label="department_name" empty_option="-- 部門 --" :disabled="departments.length === 0" @change="fetch()"></form-select>
                </div>
                <div class="col-4">
                    <form-select v-model="user" :options="users" option_value="user_id" option_label="user_name" empty_option="-- 担当者 --" :disabled="users.length === 0" @change="fetch()"></form-select>
                </div>
            </div>
        </form>

        <div class="bg-light p-3 mb-3">
            <div class="row">
                <div class="col-1 text-end text-secondary">売上</div>
                <div class="col-10">
                    <doughnut-chart :dataset="sales_chart_data" :loading="loading"></doughnut-chart>
                </div>
                <div class="col-1 offset-1 text-end text-secondary">粗利</div>
                <div class="col-10">
                    <doughnut-chart :dataset="profit_chart_data" :loading="loading"></doughnut-chart>
                </div>
            </div>
        </div>

        <table class="table">
            <thead class="table-dark">
                <tr>
                    <th class="text-center"><sorter :orderby="orderby" :order="order" column="project" @sort="fetchSorted">案件</sorter></th>
                    <th class="text-center"><sorter :orderby="orderby" :order="order" column="sales_price" @sort="fetchSorted">売上</sorter></th>
                    <th class="text-center"><sorter :orderby="orderby" :order="order" column="cost_total" @sort="fetchSorted">原価</sorter></th>
                    <th class="text-center"><sorter :orderby="orderby" :order="order" column="gross_profit" @sort="fetchSorted">粗利</sorter></th>
                    <th class="text-center"><sorter :orderby="orderby" :order="order" column="gross_profit_rate" @sort="fetchSorted">粗利率</sorter></th>
                    <th class="text-center"><sorter :orderby="orderby" :order="order" column="is_fixed" @sort="fetchSorted">状態</sorter></th>
                    <th class="text-center"></th>
                </tr>
            </thead>
            <tbody v-if="loading">
                <tr v-for="i in Array(10)" :key="i">
                    <td class="text-primary"><text-loader :width="16"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-center"><text-loader :width="6"></text-loader></td>
                    <td>
                        <button class="btn btn-primary btn-sm me-3" disabled>詳細/入力</button>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="profit of profits" :key="profit.profit_id">
                    <td class="text-primary"><router-link :to="{name: 'ProfitProject', query: {project: profit.project.project_id}}">{{ profit.project?.project_name }}</router-link></td>
                    <td class="text-end">{{ $helper.number(profit.sales_price) }}</td>
                    <td class="text-end">{{ $helper.number(profit.cost_total) }}</td>
                    <td class="text-end">{{ $helper.number(profit.gross_profit) }}</td>
                    <td class="text-end" :class="profit_rate_class(profit.sales_price, profit.gross_profit)">{{ profit.gross_profit_rate }}</td>
                    <td class="text-center">
                        <span v-if="profit.is_calculating" class="text-warning">計算中</span>
                        <span v-else-if="profit.is_fixed">確定</span>
                        <span v-else class="text-danger">未確定</span>
                    </td>
                    <td>
                        <router-link :to="{name: 'ProfitEdit', params: {profit: profit.profit_id}}" class="btn btn-primary btn-sm me-3">詳細/入力</router-link>
                    </td>
                </tr>
            </tbody>
            <tfoot v-if="loading">
                <tr>
                    <td>合計</td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td></td>
                    <td></td>
                </tr>
            </tfoot>
            <tfoot v-else>
                <tr>
                    <td>合計</td>
                    <td class="text-end">{{ $helper.number(total_sales_price) }}</td>
                    <td class="text-end">{{ $helper.number(total_cost_total) }}</td>
                    <td class="text-end">{{ $helper.number(total_gross_profit) }}</td>
                    <td class="text-end" :class="profit_rate_class(total_sales_price, total_gross_profit)">{{ total_gross_profit_rate }}</td>
                    <td></td>
                    <td></td>
                </tr>
            </tfoot>
        </table>

    </section>
</template>

<script>
import FormInputMonth from '@/components/forms/FormInputMonth';
import FormSelect from '@/components/forms/FormSelect';
import TextLoader from '@/components/tools/TextLoader';
import Profit from "@/models/entities/profit";
import Department from "@/models/entities/department";
import User from "@/models/entities/user";
import { addMonth, subMonth } from '@/utilities/month';
import DoughnutChart from '@/components/charts/DoughnutChart';
import Sorter from '@/components/tools/Sorter';

export default {
    name: 'Report',
    components: {
        FormInputMonth,
        FormSelect,
        TextLoader,
        DoughnutChart,
        Sorter,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            // month: thisMonth(), // computed に移動
            loading: true,
            profits: [],
            users: [],
            departments: [],
        }
    },
    mounted() {
        if (this.$route.query.month) {
            this.month = this.$route.query.month;
        }
        this.fetch();

        this.$http.get('/list/user')
        .then((response) => {
            this.users = response.data.map((row) => {return new User(row)});
        })

        this.$http.get('/list/department')
        .then((response) => {
            this.departments = response.data.map((row) => {return new Department(row)});
        })
    },
    methods: {
        fetch() {
            this.loading = true;
            this.profits.splice(0);
            this.$http.get('/profit', {params: {
                month: this.month,
                user_id: this.user,
                department_id: this.department,
                orderby: this.orderby,
                order: this.order,
            }})
            .then((response) => {
                for (let row of response.data) {
                    this.profits.push(new Profit(row));
                }
                this.loading = false;
            });
        },
        fetchPrev()
        {
            this.month = subMonth(this.month, 1);
            this.fetch();
        },
        fetchNext()
        {
            this.month = addMonth(this.month, 1);
            this.fetch();
        },
        fetchSorted(sort)
        {
            this.orderby = sort.orderby;
            this.order = sort.order;

            this.fetch();
        },
        view(report)
        {
            this.view_report = report;
        },
        profit_rate_class(sales_price, gross_profit)
        {
            if (sales_price === 0) {
                return null;
            } else {
                const rate = gross_profit * 100 / sales_price;
                if (rate >= this.$store.state.auth.company.profit_rate_safe) {
                    return "text-success";
                } else if (rate < this.$store.state.auth.company.profit_rate_danger) {
                    return "text-danger";
                } else {
                    return "text-warning";
                }
            }
        },
        exportCsv() {
            this.startScreenLoading();

            this.$http.post('/profit/export', {
                month: this.month,
                user_id: this.user,
                department_id: this.department,
            })
            .then(() => {
                this.$router.push({name: 'Export'});
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    },
    computed: {
        month: {
            get() {
                return this.$store.state.condition.month;
            },
            set(value) {
                this.$store.commit('condition/setMonth', value);
            },
        },
        user: {
            get() {
                return this.$store.state.condition.user;
            },
            set(value) {
                this.$store.commit('condition/setUser', value);
            },
        },
        department: {
            get() {
                return this.$store.state.condition.department;
            },
            set(value) {
                this.$store.commit('condition/setDepartment', value);
            },
        },
        orderby: {
            get() {
                return this.$store.state.pageProfit.orderby;
            },
            set(value) {
                this.$store.commit('pageProfit/setOrderBy', value);
            },
        },
        order: {
            get() {
                return this.$store.state.pageProfit.order;
            },
            set(value) {
                this.$store.commit('pageProfit/setOrder', value);
            },
        },
        total_sales_price() {
            return this.profits.reduce((total, profit) => total + profit.sales_price, 0);
        },
        total_cost_total() {
            return this.profits.reduce((total, profit) => total + profit.cost_total, 0);
        },
        total_gross_profit() {
            return this.profits.reduce((total, profit) => total + profit.gross_profit, 0);
        },
        total_gross_profit_rate() {
            if (this.total_sales_price === 0) {
                return "-";
            } else {
                return (this.total_gross_profit * 100 / this.total_sales_price).toFixed(1) + '%';
            }
        },
        sales_chart_data() {
            // 売上降順ソートして最大上位10件（＋その他）
            if (this.profits.length === 0) {
                return [];
            }

            let positives = this.profits.filter(row => row.sales_price > 0);
            if (positives.length === 0) {
                return [];
            }

            let sorted = Array.from(this.profits).sort((a, b) => {
                return a.sales_price > b.sales_price ? -1 : 1;
            });

            let chart_data = [];
            let others = 0;

            for (let profit of sorted) {
                if (chart_data.length < 10 && profit.sales_price > 0) {
                    chart_data.push({
                        label: profit.project.project_name,
                        value: profit.sales_price,
                    });
                } else {
                    others = others + profit.sales_price;
                }
            }

            if (others > 0) {
                chart_data.push({
                    label: 'その他',
                    value: others,
                });
            }

            return chart_data;
        },
        profit_chart_data() {
            // 粗利降順ソートして最大上位10件（＋その他）
            if (this.profits.length === 0) {
                return [];
            }

            let positives = this.profits.filter(row => row.gross_profit > 0);
            if (positives.length === 0) {
                return [];
            }

            let sorted = Array.from(this.profits).sort((a, b) => {
                return a.gross_profit > b.gross_profit ? -1 : 1;
            });

            let chart_data = [];
            let others = 0;

            for (let profit of sorted) {
                if (chart_data.length < 10 && profit.gross_profit > 0) {
                    chart_data.push({
                        label: profit.project.project_name,
                        value: profit.gross_profit,
                    });
                } else {
                    others = others + profit.gross_profit;
                }
            }

            if (others > 0) {
                chart_data.push({
                    label: 'その他',
                    value: others,
                });
            }

            return chart_data;
        }
    },
}
</script>

<style scoped>
</style>
